<template>
  <div class="admin-form-wysiwyg">
    <textarea :id="id" class="admin-form-wysiwyg__wrapper" :value="value"></textarea>
  </div>
</template>

<script type="text/javascript">
import '@/packages/redactor/redactor.min.js';
import '@/packages/redactor/redactor.min.css';
import '@/assets/js/fontsize.js';
import '@/assets/js/fontcolor.js';
import '@/assets/js/video.js';
import '@/assets/js/image.js';
import EventBus from '@/plugins/event-bus';

export default {
  props: {
    /**
     * Editor initial height
     */
    height: {
      default: 0,
    },

    /**
     * Editor max height
     */
    maxHeight: {
      default: 0,
    },

    /**
     * Initial value
     */
    value: {
      type: String,
      default: '',
    },

    /**
     * Update
     */
    update: {
      default: null,
    },

    /**
     * Reference
     */
    reference: {
      default: null,
    },

    /**
     * Plugins
     */
    plugins: {
      type: Array,
      default() {
        return ['visualblocks'];
      },
    },

    /**
     * Callback
     */
    callback: {
      type: Function,
      default: html => {},
    },
  },

  data() {
    return {
      id:
        Math.random()
          .toString(36)
          .substr(2, 9) +
        '-' +
        new Date().getTime(),
      isForcingHeight: false,
    };
  },

  mounted() {
    let element = this.$el.querySelector('.admin-form-wysiwyg__wrapper');
    this.plugins.push('fontsize', 'fontcolor');

    // eslint-disable-next-line no-undef
    $R(element, {
      selector: 'textarea#format-hmtl5',
      minHeight: (this.height ? this.height : 300) + 'px',
      maxHeight: (this.maxHeight ? this.maxHeight : 300) + 'px',
      buttons: [
        'html',
        'format',
        'bold',
        'italic',
        'deleted',
        'line',
        'redo',
        'undo',
        'underline',
        'ol',
        'ul',
        'indent',
        'outdent',
        'sup',
        'sub',
        'html',
      ],
      formatting: ['p', 'blockquote', 'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      // eslint-disable-next-line no-dupe-keys
      minHeight: '300px',
      plugins: 'visualblocks',
      style_formats: [
        {
          title: 'Headers',
          items: [
            { title: 'h1', block: 'h1' },
            { title: 'h2', block: 'h2' },
            { title: 'h3', block: 'h3' },
            { title: 'h4', block: 'h4' },
            { title: 'h5', block: 'h5' },
            { title: 'h6', block: 'h6' },
          ],
        },
        {
          title: 'Blocks',
          items: [
            { title: 'p', block: 'p' },
            { title: 'div', block: 'div' },
            { title: 'pre', block: 'pre' },
          ],
        },
        {
          title: 'Containers',
          items: [
            { title: 'section', block: 'section', wrapper: true, merge_siblings: false },
            { title: 'article', block: 'article', wrapper: true, merge_siblings: false },
            { title: 'blockquote', block: 'blockquote', wrapper: true },
            { title: 'hgroup', block: 'hgroup', wrapper: true },
            { title: 'aside', block: 'aside', wrapper: true },
            { title: 'figure', block: 'figure', wrapper: true },
          ],
        },
      ],
      visualblocks_default_state: true,
      end_container_on_empty_block: true,
      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      callbacks: {
        changed: html => {
          this.callback(html, this.reference);
        },
      },
    });
    EventBus.$on('getTitle', string => {
      let element = this.$el.querySelector('.admin-form-wysiwyg__wrapper');
      // eslint-disable-next-line no-undef
      $R(element, 'insertion.insertText', string);
    });
  },

  methods: {
    addText() {
      let element = this.$el.querySelector('.admin-form-wysiwyg__wrapper');
      // eslint-disable-next-line no-undef
      $R(element, 'insertion.insertText', 'addtext');
    },
  },
  computed: {},
  watch: {
    update: function() {
      let element = this.$el.querySelector('.admin-form-wysiwyg__wrapper');
      // eslint-disable-next-line no-undef
      $R(element, 'source.setCode', this.value);
    },
  },
};
</script>

<style type="text/css" lang="scss">
.redactor-in {
  box-shadow: none !important;
}

.redactor-modal-area input:focus {
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
}
.redactor-focus.redactor-styles-on,
.redactor-focus:focus.redactor-styles-on {
  border-color: $main-color !important;
}
.redactor-styles {
  margin: 0;
  padding: 16px 18px;
  color: #333;
  font-family: 'Trebuchet MS', 'Helvetica Neue', Helvetica, Tahoma, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  box-sizing: border-box;
  min-height: 400px !important;
  max-height: 400px !important;
}
</style>
